import React, { Component } from "react";
import { Route } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";
import Login from "../../pages/Login";

import ReactGA from "react-ga";
import Welcome from "../../pages/Home/Welcome";
import GolfoAirlines from "../../components/DeltaEmirates/amgolfo";
import GolfoAirlinesEN from "../../components/DeltaEmirates/amgolgoen";
import NewsCategory from "../../components/News/NewsCategory";
import NewsHome from "../../components/News/noticiaslanding";
import SingleNews from "../../components/News/SingleNews";
import SingleNewsSlider from "../../components/News/SingleNewsSlider";
import GamTalks from "../GamTalks/gamtalks";
import SolicitarNota from "../Aeronews/formsolicitar";
import InfoEnvironment from "../../utils/info.environment";
import DesempenoDesarrollo from "../Talento/talento";
import FlotaPage from "../Nosotros/flota/flota";
import ProfileRewards from "../../pages/Profile/ProfileRewards"; // NEW PROFILE REWARDS
import Triques from "../TriquesTrueques/triquestrueques";
import Search from "../../pages/Search";
import Notfound from "../../pages/404/notfound";
import TotalBenefits from "../../components/Profile/TotalBenefits";
import TotalBenefitsTrans from "../../components/Profile/TotalBenefitsTrans";
import SingleBenefitUSA from "../../components/Profile/Benefits/SingleBenefitUSA";
import ViajesServicio from "../ViajesServicio/viajesservicio";
import DiscountViewSalud from "../../components/Profile/Discounts/DescuentosSalud";
import DiscountViewEntre from "../../components/Profile/Discounts/DescuentosEntre";
import DiscountViewGym from "../../components/Profile/Discounts/DescuentosGym";
import DiscountViewCompras from "../../components/Profile/Discounts/DescuentosCompras";
import DiscountViewEduca from "../../components/Profile/Discounts/DescuentosEduca";
import DiscountViewResta from "../../components/Profile/Discounts/DescuentosResta";
import DiscountViewCar from "../../components/Profile/Discounts/DescuentosCar";
import DiscountViewSer from "../../components/Profile/Discounts/DescuentosSer";
import DiscountViewGuar from "../../components/Profile/Discounts/DescuentosGuar";
import Finanzas from "../../components/Profile/Finanzas/finanzas";
import Experience from "../../components/Profile/Experience/experience";
import LovedBenefits from "../Profile/LovedBenefits/lovedBenefits";
import ComiteEjecutivo from "../../components/ComiteEjecutivo/comiteEjecutivo";
import CustomerExperience from "../../components/CustomerExperience/customerexperience";
import Nosotros from "../../components/Nosotros/transformacion";
import Responsabilidad from "../../components/Nosotros/responsabilidad";
import Vacantes from "../Profile/vacantes";
import CXevolution from "../../components/CXevolution/cxevolution";
import Seguridad from "../../components/seguridad/seguridad";
import NOM035 from "../../pages/NOM035/Nom035";
import Flightplan from "../flightplan/fligthplan";
import Flightplanpor from "../flightplan/fligthplanpor";
import Flightplanen from "../flightplan/fligthplanen";
import Flightplandut from "../flightplan/flightplandut";
import Flightplanjap from "../flightplan/flightplanjap";
import Flightplanfr from "../flightplan/flightplanfr";
import Flightplanchino from "../flightplan/flightplanchino";
import Flightplancor from "../flightplan/flightplancor";
import Logrocx from "../CustomerExperience/logroscx";
import Identidad from "../Nosotros/identidad";
import Alfabeto from "../Nosotros/alfabeto";
import Reportesostenibilidad from "../Nosotros/reportesostenibilidad";
import Reportetrimestral from "../Nosotros/reportetrimestral";
import Reporteoperacional from "../Nosotros/reporteoperacional";
import Reporteanual from "../Nosotros/reporteanual";
import Boletin from "../Nosotros/boletin";
import Jcaaeromexicodelta from "../Nosotros/jcaaeromexicodelta";
import ErrorMessage from "../../ErrorMessage";
import ApplyForm from "../../components/Profile/Apply/Form";
import config from "../../Config";
import { UserAgentApplication } from "msal";
import Maps from "../../pages/Geolocation/Mapa";
import Map2 from "../../components/Geolocation/Map/Map2";
import ReporteSostenibilidad from "../../components/Geolocation/Map/framer";
import userLocation from "../../utils/userLocation";
import isMockUser from "../../utils/isMockUser";
import setLevelMockUser from "../../utils/setLevelMockUser";
import setMockCompany from "../../utils/setMockCompany";
import CompliancePage from "../../pages/Compliance/CompliancePage";
import EmailSearch from "../../pages/Hidden/EmailSearch";
import { ComunicadosPage } from "../../pages/Comunicados/ComunicadosPage";
import Clasificado from "../../pages/Clasificado/Clasificado";
import { createBrowserHistory } from "history";
import Juntos from "../../pages/Juntos/Juntos";
import DesdeCasa from "../../pages/DesdeCasa/DesdePage";
import FuturoPage from "../../pages/Futuro/FuturoPage";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import logger from "redux-logger";
import thunk from "redux-thunk";
import { save, load } from "redux-localstorage-simple";
import rootReducer from "../../redux/rootReducer";
import Max737 from "../../pages/737Max";
import Teletrabajo from "../../pages/Teletrabajo";
import { validateMenu } from "../../utils/NewBenefitsValidation/benefits.validation";
import TalentPlanningPage from "../../pages/TalentPlanning";
import MyLearningPage from "../MyLearning";
import MiLineaAtencion from "../../pages/LineaAtencion";
import PageProcesosAdministrativos from "../../pages/ProcesosAdministrativos";
import AccionesPersonales from "../../pages/AccionesPersonales";
import BenefitsOpenMX from "../Profile/Benefits/BenefitsOpenMX";
import BazarNavidad from "../BazarNavidad";
import negociacionesASPA2024 from "../negociacionesASPA2024";
import MyHR from "../TriquesTrueques/triquestrueques";
import Triques2 from "../TriquesTrueques/triquestrueques2";
import IndexHeader from "../Header";
import ReloadOnUrlChange from "../News/ReloadOnUrlChange";
import { Switch } from "react-router-dom";
import IndexUpdate from "../../pages/Login/indexUpdate";
import LoginOktaReact from "../../auth/LoginOktaReact";
import Layout from "../Layout";
import { Redirect } from "react-router-dom";
import { Security, SecureRoute, ImplicitCallback } from "@okta/okta-react";


const middleware = [logger, thunk];

const store = createStore(rootReducer, load(), composeWithDevTools(applyMiddleware(...middleware, save())));

const sha1 = require("sha1");
ReactGA.initialize("UA-138315711-1");
ReactGA.event({
	category: "Log In",
	action: "User logged into the site"
});

const history = createBrowserHistory();

history.listen((location) => {
	ReactGA.set({ page: location.pathname });
	ReactGA.pageview(location.pathname);
});

ReactGA.set();

ReactGA.pageview("/login");
ReactGA.pageview("/inicio");
ReactGA.pageview("/nosotros");
ReactGA.modalview("/inicio");
ReactGA.modalview("/recompensa-total/perfil/venta-especial");
ReactGA.pageview("/recompensa-total/perfil/venta-especial");
ReactGA.pageview("/defendamos-nuestro-cielo");
ReactGA.pageview("/articles/", ["tracker2"]);
ReactGA.pageview("/nosotros/plan-de-vuelo");
ReactGA.pageview("/nosotros/responsabilidad-social");
ReactGA.pageview("/nosotros/jca-aeromexico-delta");
ReactGA.pageview("/nosotros/gam-talks");
ReactGA.pageview("/seguridad");
ReactGA.pageview("/oportunidades-carrera");
ReactGA.pageview("/recompensa-total/my-hr");
ReactGA.pageview("/recompensa-total/viajes-aeromexico");
ReactGA.pageview("/experiencia-cliente");
ReactGA.pageview("/experiencia-cliente/logros");
ReactGA.pageview("/nosotros/responsabilidad-social/informe-sostenibilidad");
ReactGA.pageview("recompensa-total/mi-desempeno-desarrollo");

ReactGA.pageview("/articles/5I59C1oQqeZRjBANfYJCYP");

ReactGA.pageview("/experiencia-cliente/cx-evolution");
ReactGA.pageview("/buy-and-sell");

ReactGA.pageview("/recompensa-total/perfil/mi-compensaciones");
ReactGA.pageview("/recompensa-total/perfil/my-travel-benefits");
ReactGA.pageview("/recompensa-total/perfil/mis-beneficios");
ReactGA.pageview("/recompensa-total/perfil");
ReactGA.pageview("/seguridad#gamreport");

ReactGA.pageview("/recompensa-total/mis-beneficios/descuentos/salud-y-belleza");
ReactGA.pageview("/recompensa-total/mis-beneficios/descuentos/entretenimiento");
ReactGA.pageview("/recompensa-total/mis-beneficios/descuentos/gimnasios");
ReactGA.pageview("/recompensa-total/mis-beneficios/descuentos/de-compras");
ReactGA.pageview("/recompensa-total/mis-beneficios/descuentos/educacion");
ReactGA.pageview("/recompensa-total/mis-beneficios/descuentos/restaurantes");
ReactGA.pageview("/recompensa-total/mis-beneficios/descuentos/automoviles");
ReactGA.pageview("/recompensa-total/mis-beneficios/descuentos/servicios");
ReactGA.pageview("/recompensa-total/mis-beneficios/descuentos/guarderias");

/* SET USER ID TO ANALYTICS */
if (localStorage.getItem("email") !== null) {
	ReactGA.set({ userId: localStorage.getItem("email") });
	ReactGA.set({ dimension1: localStorage.getItem("email") });
}

if (
	localStorage.getItem("nivel") !== null &&
	localStorage.getItem("nivel") !== "" &&
	localStorage.getItem("nivel") !== ""
) {
	ReactGA.set({ dimension2: "NSD" });
} else {
	ReactGA.set({ dimension2: "SND" });
}

// OKTA CLIENT ID: 0oasu4e8ivTw8wA0I0h7
// OKTA CLIENT SECRET: YnpN5xP5w4nd_IWX2Q5quDzo6WlxzmaQzZqyrUC6

const PRoute = ({ component: Component,  ...rest }) => (
	<Route
		{...rest}
		render={(props) =>
			localStorage.getItem("token") !== null ? (
				<Component {...props} />
			) : (
				<Redirect
					to={`/login?redirectTo=${window.encodeURIComponent(
						props.location.pathname + props.location.search
					)}`}
				/>
			)
		}
	/>
);

const url = new URL(window.location.href);
const pathElements = url.pathname.split("/");
const elementoSeguridad = pathElements[pathElements.length - 1];

function onAuthRequired({ history }) {
	history.push("/login");
}

export default class App extends Component {
	/**OFFICE */
	constructor(props) {
		super(props);
		this.infoEnvironment = new InfoEnvironment();
		this.userAgentApplication = new UserAgentApplication(config.appId, null, null);
		var user = this.userAgentApplication.getUser();
		this.state = {
			isAuthenticated: user !== null, // Se quita el signo ! para entrar por pruebas y se pone para activar el redireccionamiento
			user: {},
			error: null,
			charging: true,
			errorApi: false
		};

		if (user) {
			// Enhance user object with data from Graph
			// this.getUserProfile(); REVISARALEX
		}
	}

	async login() {
		try {
			await this.userAgentApplication.loginPopup(config.scopes); //Validar si esta gate-one para probar la auth
			// await this.getUserProfile();  REVISARALEX
		} catch (err) {
			var errParts = err.split("|");
			this.setState({
				isAuthenticated: false,
				user: {},
				error: { message: errParts[1], debug: errParts[0] }
			});
		}
	}

	componentDidMount() {
		this.getInfo();
		this.intervalo = setInterval(() => {
			this.verificarInactividad();
		}, 1 * 1000);
	}

	componentWillUnmount() {
		// Limpiar el temporizador cuando el componente se desmonta
		clearInterval(this.intervalo);
	}
	
	verificarInactividad() {
		//const tiempoInactivo = 1 * 60 * 1000;

		if (localStorage.getItem("accessDate") !== null) {
			// alert(localStorage.getItem('accessDate'));
			const tiempoInactivo = 30 * 60 * 1000;
			const tiempoActual = new Date();
			const lastActiveTime = new Date(localStorage.getItem("accessDate"));
			const tiempoTranscurrido = tiempoActual - lastActiveTime;

			if (tiempoTranscurrido > tiempoInactivo) {
				this.logout();
				window.location.assign("/login");
			}
		} else if (localStorage.getItem("accessDate") == null && localStorage.getItem("oktaToken") !== null) {
			this.logout();
			window.location.assign("/login");
		}
	}

	logout() {
		var lang = localStorage.getItem("i18nextLng");
		localStorage.clear();
		sessionStorage.clear();
		localStorage.setItem("i18nextLng", lang);
		this.userAgentApplication.logout();
	}
	

	setErrorMessage(message, debug) {
		this.setState({
			error: { message: message, debug: debug }
		});
	}

	setUserLocation() {
		var location = userLocation(
			localStorage.getItem("email"),
			localStorage.getItem("ubicacion"),
			localStorage.getItem("compania")
		);

		localStorage.setItem("location", location);
	}

	getInfo = async () => {

		// if (typeof Buffer !== "undefined") {
			if (localStorage.getItem("email") !== null && localStorage.getItem("nombre") !== null) {
			
				const HOST = "NOMBREHOST";

				const token = sha1(`${HOST}|${localStorage.getItem("email")}`).toUpperCase();
				// const email = new Buffer(localStorage.getItem("email")).toString("hex").toUpperCase();
				const email = this.stringToHex(localStorage.getItem("email")).toUpperCase(); 
				// console.log('email hex',email)
				const URL = this.infoEnvironment.api_url({
					host: window.location.hostname
				});

				let formData = new FormData();

				formData.append("token", token);
				formData.append("email", email);
				formData.append("tipo", "1");
				formData.append("tipo_salida", "JSON");
	
				fetch(URL, {
					body: formData,
					method: "post"
				})
					.then((data) => data.json()) 
					.then((data) => {
						
						if (!!data.subordinados) {
							localStorage.setItem("subordinados", data.subordinados);
						}
						localStorage.setItem("nombreHRBP", data.nombrehrbp);
						localStorage.setItem("emailHRBP", data.emailhrbp);
						localStorage.setItem("nombre", data.nombre);
						localStorage.setItem("grupo", data.grupo);
						localStorage.setItem("pais", data.country);
						localStorage.setItem("codigo_empresa_AM", data.codigo_empresa);
						localStorage.setItem("tipo_empleado_AM", data.tipo_empleado);
						localStorage.setItem("no_empleado_AM", data.noEmpleado);

						this.setState({
							error: false,
							charging: false
						});

						if (data.noEmpleado !== "") {
							localStorage.setItem("noEmpleado", data.noEmpleado);
							localStorage.setItem("compania", data.compania);
							localStorage.setItem("nombre", data.nombre);
							localStorage.setItem("pais", data.country);
							localStorage.setItem("apellidoMaterno", data.apellidoMaterno);
							localStorage.setItem("apellidoPaterno", data.apellidoPaterno);
							localStorage.setItem("grupo", data.grupo);
							localStorage.setItem("nivel", data.nivel);
							localStorage.setItem("ubicacion", data.ubicacion);
							localStorage.setItem("posicion", data.posicion);
							localStorage.setItem("direccion", data.direccion);
							localStorage.setItem("direccionArea", data.direccionArea);
							localStorage.setItem("esquemaColaborador", data.esquemacolaborador);
							localStorage.setItem("colaboradores", JSON.stringify(data.COLABORADORES));
							localStorage.setItem("fechaContra", data.fechaContra);
							localStorage.setItem("antiguedadEmpleado", data.antiguedadEmpleado);
							localStorage.setItem("flexIsActive", true)
						} else {
							if (isMockUser()) {
								localStorage.setItem("noEmpleado", "-");
								setMockCompany();
								localStorage.setItem("nombre", "Usuario");
								localStorage.setItem("pais", "MX");
								localStorage.setItem("apellidoMaterno", "-");
								localStorage.setItem("apellidoPaterno", "-");
								localStorage.setItem("grupo", "XXXX");
								setLevelMockUser();
								localStorage.setItem("ubicacion", "-");
								localStorage.setItem("posicion", "");
								localStorage.setItem("direccion", "-");
								localStorage.setItem("direccionArea", "-");
								localStorage.setItem("fechaContra", "-");
								localStorage.setItem("antiguedadEmpleado", "-");
							} else {
								localStorage.setItem("noEmpleado", "-");
								localStorage.setItem("compania", "-");
								localStorage.setItem("nombre", "-");
								localStorage.setItem("pais", "MX");
								localStorage.setItem("apellidoMaterno", "-");
								localStorage.setItem("apellidoPaterno", "-");
								localStorage.setItem("grupo", "un grupo");
								localStorage.setItem("nivel", 0);
								localStorage.setItem("ubicacion", "-");
								localStorage.setItem("posicion", "");
								localStorage.setItem("direccion", "-");
								localStorage.setItem("direccionArea", "-");
								localStorage.setItem("fechaContra", "-");
								localStorage.setItem("antiguedadEmpleado", "-");
							}
						}
					})
					.catch((error) => {
						this.setState({
							errorApi: error
						});
					});

				this.setUserLocation();
			}
		// }
	};

	stringToHex(str){
		let hex = '';
		for(let i=0; i<str.length;i++){
			hex += str.charCodeAt(i).toString(16).toUpperCase();
		}
		return hex;
	}

	render() {
		let error = null;

		if (this.state.error) {
			error = <ErrorMessage message={this.state.error.message} debug={this.state.error.debug} />;
		}

		return (
			<Provider store={store}>
				<Router>
					<Security 
						//client_id="0oaw9rnju9yDyiffv0h7" // se desconoce
						// client_id="00T2o75v1wO0yYGga0h7" // pruebas
						// issuer="https://lobbygateone.amx-digital.com/oauth2/default"
						issuer="https://gateone.amx-digital.com/oauth2/default"
						client_id="00T22w36gvMLBBlWl4x7" // prod
						redirect_uri={window.location.origin + "/login/callback"}
						onAuthRequired={onAuthRequired}>
						<Switch>
							<Route
								path="/okta"
								// render={() => <LoginOktaReact baseUrl="https://lobbygateone.amx-digital.com" />}
								render={() => <LoginOktaReact baseUrl="https://gateone.amx-digital.com" />}
							/>
							<Route path="/login/callback" component={ImplicitCallback} />

							{error}
							<Route history={history} exact path="/login" component={Login} />
							<Route history={history} exact path="/" component={RedirectLogin} />
							<Route history={history} exac path="/bazar-navideno" component={BazarNavidad} />
							<Route history={history} exac path="/negociacionesASPA" component={negociacionesASPA2024} />
							<Route history={history} exac path="/creandoelfuturo" component={FuturoPage} />
							<Route history={history} exact path="/solicitar-nota-aeronews" component={SolicitarNota} />
							<Layout>
								<Switch>
									<PRoute
										history={history}
										exact
										path="/defendamos-nuestro-cielo"
										component={GolfoAirlines}
									/>
									<PRoute
										history={history}
										exact
										path="/lets-defend-our-sky"
										component={GolfoAirlinesEN}
									/>
									<PRoute history={history} exact path="/" component={Welcome} />
									<PRoute history={history} exact path="/inicio" component={Welcome} />
									{/* <SecureRoute
										path="/inicio"
										render={() =>
											this.state.isAuthenticated ? <Welcome /> : <Redirect to="/login" />
										}
									/> */}
									<PRoute history={history} exact path="/nosotros/gam-talks" component={GamTalks} />
									<PRoute
										history={history}
										exact
										path="/recompensa-total"
										component={(props) => <ProfileRewards activeTab="rewards" {...props} />}
									/>
									<PRoute
										history={history}
										exact
										path="/recompensa-total/perfil"
										component={(props) => <ProfileRewards {...props} activeTab="rewards"  />}
									/>
									<PRoute
										history={history}
										exact
										path="/recompensa-total/perfil/mi-compensaciones"
										component={(props) => (
											<>
												{validateMenu() ? (
													<ProfileRewards {...props} activeTab="rewards" />
												) : (
													<Redirect to="/" />
												)}
											</>
										)}
									/>
									<PRoute
										history={history}
										exact
										path="/recompensa-total/perfil/my-flexibility"
										component={
											(props) => <
												ProfileRewards {...props} 
												activeTab="flexibility" 

												/>
											}
									/>
									<PRoute
										history={history}
										exact
										path="/recompensa-total/perfil/my-travel-benefits"
										component={(props) => <ProfileRewards {...props} activeTab="travel" />}
									/>
									<PRoute history={history} exact path="/compliance" component={CompliancePage} />
									{/* COMUNICADOS */}
									<PRoute history={history} exact path="/comunicados" component={ComunicadosPage} />
									<PRoute
										history={history}
										exact
										path="/juntoscontinuaremosvolando"
										component={Juntos}
									/>
									<PRoute
										history={history}
										exact
										path="/nosotros/seguimos-y-seguiremos-volando"
										component={Clasificado}
									/>
									<PRoute history={history} exact path="/juntos-desde-casa" component={DesdeCasa} />
									<PRoute
										history={history}
										exact
										path="/hidden/emailSearch"
										component={EmailSearch}
									/>
									<PRoute
										history={history}
										exact
										path="/recompensa-total/perfil/venta-especial"
										component={(props) => <ProfileRewards {...props} activeTab="ventaespecial" />}
									/>
									<PRoute
										history={history}
										exact
										path="/ayuda-teletrabajo"
										component={(props) => <Teletrabajo />}
									/>
									<PRoute
										history={history}
										exact
										path="/recompensa-total/perfil/mis-beneficios"
										component={(props) => <ProfileRewards {...props} activeTab="benefits" />}
									/>
									<PRoute history={history} exact path="/mis-beneficios" component={BenefitsOpenMX} />

									<PRoute
										history={history}
										exact
										path="/benefits-usa/:articleId"
										component={SingleBenefitUSA}
									/>
									<PRoute history={history} exact path="/recompensa-total/my-hr">
										<Redirect to="/inicio" />
									</PRoute>
									<PRoute
										history={history}
										exact
										path="/recompensa-total/talent-planning"
										component={TalentPlanningPage}
									/>
									<PRoute
										history={history}
										exact
										path="/oportunidades-carrera"
										component={Vacantes}
									/>
									<PRoute
										history={history}
										exact
										path="/recompensa-total/mi-desempeno-desarrollo"
										component={DesempenoDesarrollo}
									/>
									<PRoute
										history={history}
										exact
										path="/recompensa-total/my-learning"
										component={MyLearningPage}
									/>

									<PRoute
										history={history}
										exact
										path="/oportunidades-carrera/aplicar-vacante/:jobId"
										component={ApplyForm}
									/>
									<PRoute history={history} exact path="/maps" component={Maps} />
									<PRoute history={history} exact path="/nosotros/nuestras-rutas">
										<Redirect to="/inicio" />
									</PRoute>
									<PRoute
										history={history}
										exact
										path="/nosotros/responsabilidad-social/informe-sostenibilidad"
										component={ReporteSostenibilidad}
									/>

									<PRoute history={history} exact path="/map2" component={Map2} />
									<PRoute
										history={history}
										exact
										path="/recompensa-total/perfil/mis-beneficios/disfruta-tu-trabajo"
										component={TotalBenefits}
									/>

									<PRoute
										history={history}
										exact
										path="/recompensa-total/perfil/mis-beneficios/disfruta-tu-trabajo/transportacion"
										component={TotalBenefitsTrans}
									/>

									<PRoute
										history={history}
										exact
										path="/recompensa-total/perfil/mis-beneficios/administra-tus-finanzas"
										component={Finanzas}
									/>
									<PRoute
										history={history}
										exact
										path="/recompensa-total/perfil/mis-beneficios/vive-aeromexico"
										component={Experience}
									/>
									<PRoute
										history={history}
										exact
										path="/recompensa-total/perfil/mis-beneficios/cuida-de-ti"
										component={LovedBenefits}
									/>

									<PRoute
										history={history}
										exact
										path="/recompensa-total/mis-beneficios/descuentos/salud-y-belleza"
										component={DiscountViewSalud}
									/>
									<PRoute
										history={history}
										exact
										path="/recompensa-total/mis-beneficios/descuentos/entretenimiento"
										component={DiscountViewEntre}
									/>
									<PRoute
										history={history}
										exact
										path="/recompensa-total/mis-beneficios/descuentos/gimnasios"
										component={DiscountViewGym}
									/>
									<PRoute
										history={history}
										exact
										path="/recompensa-total/mis-beneficios/descuentos/de-compras"
										component={DiscountViewCompras}
									/>
									<PRoute
										history={history}
										exact
										path="/recompensa-total/mis-beneficios/descuentos/educacion"
										component={DiscountViewEduca}
									/>
									<PRoute
										history={history}
										exact
										path="/recompensa-total/mis-beneficios/descuentos/restaurantes"
										component={DiscountViewResta}
									/>
									<PRoute
										history={history}
										exact
										path="/recompensa-total/mis-beneficios/descuentos/automoviles"
										component={DiscountViewCar}
									/>
									<PRoute
										history={history}
										exact
										path="/recompensa-total/mis-beneficios/descuentos/servicios"
										component={DiscountViewSer}
									/>
									<PRoute
										history={history}
										exact
										path="/recompensa-total/mis-beneficios/descuentos/guarderias"
										component={DiscountViewGuar}
									/>

									<PRoute
										history={history}
										exact
										path="/recompensa-total/viajes-aeromexico"
										component={ViajesServicio}
									/>

									{/* <PRoute history={history} exact path="/buy-and-sell" component={Triques2} /> */}
									<PRoute history={history} exact path="/buy-and-sell" component={Triques}/>
									{/*noticias*/}
									<PRoute history={history} exact path="/noticias" component={NewsHome} />
									<PRoute
										history={history}
										exact
										path="/noticias/ultimas-noticias"
										component={NewsHome}
									/>
									<PRoute history={history} path="/noticias/:newsId" component={NewsCategory} />

									<PRoute
										history={history}
										exact
										path="/articles/:articleId"
										component={SingleNews}
									/>
									<PRoute
										history={history}
										path="/articles/slider/:articleSliderId"
										component={SingleNewsSlider}
									/>
									<PRoute history={history} exact path="/buscar" component={Search} />

									<PRoute history={history} exact path="/seguridad" component={Seguridad} />
									<PRoute history={history} exact path="/seguridad/nom-035" component={NOM035} />

									<PRoute
										history={history}
										exact
										path="/mi-linea-atencion"
										component={MiLineaAtencion}
									/>
									<PRoute
										history={history}
										exact
										path="/servicios-personal"
										component={PageProcesosAdministrativos}
									/>
									<PRoute
										history={history}
										exact
										path="/acciones-personales"
										component={AccionesPersonales}
									/>

									{/* 737 Max */}
									<PRoute history={history} exact path="/boeing-737-max" component={Max737} />

									{/* Nosotros */}
									<PRoute
										history={history}
										exact
										path="/nosotros/sobre-nosotros"
										component={Nosotros}
									/>
									<PRoute
										history={history}
										exact
										path="/nosotros/nuestra-flota"
										component={FlotaPage}
									/>
									<PRoute history={history} exact path="/nosotros" component={Nosotros} />
									<PRoute
										history={history}
										exact
										path="/nosotros/responsabilidad-social"
										component={Responsabilidad}
									/>
									<PRoute
										history={history}
										exact
										path="/nosotros/nuestra-organizacion"
										component={ComiteEjecutivo}
									/>
									<PRoute
										history={history}
										exact
										path="/nosotros/logos-templates"
										component={Identidad}
									/>
									<PRoute history={history} exact path="/nosotros/alfabeto" component={Alfabeto} />
									<PRoute
										history={history}
										exact
										path="/nosotros/reporte-sustentabilidad"
										component={Reportesostenibilidad}
									/>
									<PRoute
										history={history}
										exact
										path="/nosotros/reporte-operacional"
										component={Reporteoperacional}
									/>
									<PRoute
										history={history}
										exact
										path="/nosotros/reporte-anual"
										component={Reporteanual}
									/>
									<PRoute
										history={history}
										exact
										path="/nosotros/reporte-trimestral"
										component={Reportetrimestral}
									/>

									<PRoute
										history={history}
										exact
										path="/nosotros/boletin-industria"
										component={Boletin}
									/>

									<PRoute
										history={history}
										exact
										path="/nosotros/jca-aeromexico-delta"
										component={Jcaaeromexicodelta}
									/>

									{/* Plan de Vuelo */}
									<PRoute
										history={history}
										exact
										path="/nosotros/plan-de-vuelo"
										component={Flightplan}
									/>
									<PRoute
										history={history}
										exact
										path="/nosotros/plan-de-vuelo/ingles"
										component={Flightplanen}
									/>
									<PRoute
										history={history}
										exact
										path="/nosotros/plan-de-vuelo/portugues"
										component={Flightplanpor}
									/>
									<PRoute
										history={history}
										exact
										path="/nosotros/plan-de-vuelo/holandes"
										component={Flightplandut}
									/>
									<PRoute
										history={history}
										exact
										path="/nosotros/plan-de-vuelo/japones"
										component={Flightplanjap}
									/>
									<PRoute
										history={history}
										exact
										path="/nosotros/plan-de-vuelo/frances"
										component={Flightplanfr}
									/>
									<PRoute
										history={history}
										exact
										path="/nosotros/plan-de-vuelo/chino"
										component={Flightplanchino}
									/>
									<PRoute
										history={history}
										exact
										path="/nosotros/plan-de-vuelo/coreano"
										component={Flightplancor}
									/>

									{/* Experiencia al Cliente */}
									<PRoute
										history={history}
										exact
										path="/experiencia-cliente"
										component={CustomerExperience}
									/>
									<PRoute
										history={history}
										exact
										path="/experiencia-cliente/logros"
										component={Logrocx}
									/>
									<PRoute
										history={history}
										exact
										path="/experiencia-cliente/net-promoter-score"
										component={CustomerExperience}
									/>
									<PRoute
										history={history}
										exact
										path="/experiencia-cliente/cx-evolution"
										component={CXevolution}
									/>
									<PRoute
										history={history}
										exact
										path="/reciclandoando"
										component={() => {
											const getMobileOS = () => {
												const userAgent =
													navigator.userAgent || navigator.vendor || window.opera;
												if (/android/i.test(userAgent)) {
													return true;
												}
												if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
													return true;
												}
												return false;
											};
											if (!getMobileOS()) {
												return (
													<iframe
														className="reciclando-iframe"
														title="Reciclando Ando"
														src="https://pixpik.org/aeromexico/"
														// width="100%"
														// height="700"
														frameborder="0"
														style={{
															height: "700px !important"
														}}></iframe>
												);
											} else {
												return (window.location.href = "https://pixpik.org/aeromexico/");
											}
										}}
									/>
									<PRoute history={history} component={Notfound} />
								</Switch>
							</Layout>
						</Switch>
					</Security>
				</Router>
			</Provider>
		);
	}
}
const RedirectLogin = () => <Redirect to="/login" />;
